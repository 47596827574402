<template>
  <div class="caseDataInfo" v-if="isShow">
    <div class="infoOne">
      <el-form
        :model="caseDataInfo"
        :rules="rulesCaseDataInfo"
        ref="rulesCaseDataInfo"
        label-width="140px"
      >
        <el-form-item label="报告图表：" prop="reportImg">
          <UpLoadImage
            :imageUrl.sync="caseDataInfo.reportImgUrl"
            :saveUrl.sync="caseDataInfo.reportImg"
          />
        </el-form-item>
        <el-form-item label="图片名称：" prop="imgName">
          <el-input v-model="caseDataInfo.imgName" class="lager-input" />
        </el-form-item>
        <el-form-item label="图片描述：" prop="imgDescribe">
          <el-input
            v-model="caseDataInfo.imgDescribe"
            style="width:70%"
            type="textarea"
            :autosize="{ minRows: 8, maxRows: 20 }"
          />
        </el-form-item>
        <el-form-item label="关联报告名称：" prop="reportName">
          <el-select
            v-model="caseDataInfo.reportName"
            filterable
            allow-create
            remote
            reserve-keyword
            placeholder="请输入关键词"
            :remote-method="remoteMethod"
            @change="changeCompany(caseDataInfo.reportName)"
            :loading="loading"
            style="width: 470px"
            :clearable="true"
          >
            <el-option
              v-for="(item, index) in options"
              :key="index"
              :label="item.reportName"
              :value="item.reportName"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="发布日期：" prop="version">
          <el-input v-model="caseDataInfo.publishDate" class="lager-input" />
        </el-form-item>
        <el-form-item label="领域标签：" v-if="personType">
          <el-tag
            v-for="(item, index) in this.caseDataInfo.fieldCategory"
            :key="index"
            >{{ item }}</el-tag
          >
        </el-form-item>
        <el-form-item label="技术标签：" v-if="personType">
          <el-tag
            v-for="(item, index) in this.caseDataInfo.techCategory"
            :key="index"
            >{{ item }}</el-tag
          >
        </el-form-item>
      </el-form>
      <div class="ess_people" v-if="personType">
        <div class="ess_people_list">创建人：{{ caseDataInfo.createName }}</div>
        <div class="ess_people_list">修改人：{{ caseDataInfo.updateName }}</div>
        <div class="ess_people_list">
          创建时间：{{ caseDataInfo.createTime }}
        </div>
        <div class="ess_people_list">
          修改时间：{{ caseDataInfo.updateTime }}
        </div>
      </div>
    </div>
    <div class="bottom">
      <el-button type="primary" @click="saveItem">保存</el-button>
      <el-button @click="cancelItem">取消</el-button>
    </div>
  </div>
</template>

<script>
import UpLoadImage from "./uploadImg";
import uploadFile from "../../PolicyData/uploadFile";
import DicRadioGroup from "@/components/DicRadioGroup.vue";
import {
  getReportImage,
  insertReportImage,
  updateReportImage,
  selectLikeByReportName
} from "@/api/report";
import DataTime from "@/components/DataTime.vue";
import DicCheckboxGroup from "@/components/DicCheckboxGroup.vue";
export default {
  name: "CaseDataInfo",

  components: {
    DataTime,
    DicCheckboxGroup,
    DicRadioGroup,
    UpLoadImage,
    uploadFile
  },

  watch: {
    "caseDataInfo.id": {
      handler(newVal) {
        if (this.caseDataInfo && newVal) {
          this.isShow = true;
        }
      },
      deep: true,
      immediate: true
    }
  },

  data() {
    return {
      isShow: false,
      caseDataInfo: {
        imgDescribe: "",
        imgName: "",
        publishDate: "",
        reportImg: "",
        reportName: "",
        reportImg: ""
      },
      options: [],
      rulesCaseDataInfo: {
        imgName: [
          { required: true, message: "请输入图片名称", trigger: "blur" }
        ],
        reportImg: [{ required: true, message: "请上传图片", trigger: "blur" }]
      },
      loading: false
    };
  },

  computed: {
    personType() {
      return Number(this.$route.query.personType);
    },
    policyId() {
      return Number(this.$route.query.id);
    },
    editInfo() {
      return this.$route.params.editInfo;
    },
    Authorization() {
      return sessionStorage.getItem("token");
    }
  },

  created() {
    if (this.personType) {
      this.search();
    }
    if (!this.personType) {
      this.isShow = true;
    }
  },

  methods: {
    async search() {
      const data = {
        id: this.policyId
      };
      const res = await getReportImage(data);
      if (res.code == 200) {
        this.caseDataInfo = res.data;
      } else {
        this.$message.error("查询失败");
      }
    },
    formatNull(val) {
      if (val === null) {
        return [];
      } else {
        return val;
      }
    },
    // 将字符串格式化成数组
    formatString(val) {
      if (!val) {
        return [];
      } else if (!val.length) {
        return val;
      } else {
        return val.split(",");
      }
    },
    // 保存
    saveItem() {
      this.$refs.rulesCaseDataInfo.validate(async valid => {
        if (valid) {
          let res;
          if (this.personType) {
            res = await updateReportImage(this.caseDataInfo);
            if (res.code == 200) {
              this.$message.success("编辑成功");
              this.$router.go(-1);
            } else {
              this.$message.error("编辑失败");
            }
          } else {
            res = await insertReportImage(this.caseDataInfo);
            if (res.code == 200) {
              this.$message.success("新增成功");
              this.$router.go(-1);
            } else {
              this.$message.error("新增失败");
            }
          }
        }
      });
    },
    // 取消
    cancelItem() {
      this.$confirm("确认退出吗 ?", "提示")
        .then(() => {
          this.$router.go(-1);
        })
        .catch(() => {});
    },
    remoteMethod(query) {
      if (query !== "") {
        this.loading = true;
        setTimeout(async () => {
          this.loading = false;
          const companyName = query;
          const res = await selectLikeByReportName({ reportName: companyName });
          this.options = res.data;
        }, 200);
      } else {
        this.options = [];
      }
    },
    changeCompany(data) {
      var data1 = this.options.find(item => {
        return item.reportName == data;
      });
      this.caseDataInfo.publishDate = data1.reportTime;
    }
  }
  // mounted() {
  //   document.getElementsByClassName("infoOne")[0].style.height =
  //     document.body.clientHeight - 200 + "px";
  // }
};
</script>

<style lang="less" scoped>
.infoOne {
  overflow: auto;
}
.bottom {
  border-top: 1px solid #dbdbdb;
  background: #fff;
  padding: 10px 20px;
  display: flex;
  justify-content: center;
  .el-button {
    margin: 0 20px;
  }
}
.caseDataInfo {
  background-color: #fff;
  padding: 20px 20px 0 20px;
  .el-tag + .el-tag {
    margin-left: 10px;
  }
  .button-new-tag {
    margin-left: 10px;
    height: 32px;
    line-height: 30px;
    padding-top: 0;
    padding-bottom: 0;
  }
  .input-new-tag {
    width: 90px;
    margin-left: 10px;
    vertical-align: bottom;
  }
}
/deep/.el-upload--picture-card {
  width: 200px !important;
  height: 120px !important;
  line-height: 120px !important;
}
/deep/ .el-upload-list--picture-card .el-upload-list__item {
  width: 200px !important;
  height: 120px !important;
}
.policyData {
  display: flex;
  // justify-content: space-between;
}
.ess_people {
  display: flex;
  flex-direction: column;
  // overflow: hidden;
  margin: 20px 30px;
  align-content: center;

  .ess_people_list {
    width: 100%;
    margin: 4px 0;
    color: #7c7f8e;
    font-size: 12px;
  }
}
/deep/ .el-input-group__append,
.el-input-group__prepend {
  background-color: #fff;
  font-size: 20px;
}
</style>
